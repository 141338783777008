//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api              from '@api/index';
import HydraHelper           from '@helper/Hydra';
import { ShortclipsActions } from '@slices/shortclips';

function* fetchShortclips() {
    const response = yield call(Api.context.shortclips.fetchShortclips);

    if (response.ok) {
        const shortclips = HydraHelper.getMembersFromResponse(response.data);

        yield put(ShortclipsActions.fetchShortclipsSucceeded({
            shortclips,
        }));
    } else {
        yield put(ShortclipsActions.fetchShortclipsFailed());
    }
}

export default {
    fetchShortclips,
};
