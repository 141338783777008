//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';

const initialState = Object.freeze({
    open:  false,
    video: {},
});

const videoOverlaySlice = createSlice({
    name:     'videoOverlay',
    initialState,
    reducers: {
        open:  (state, action) => {
            return update(state, {
                open:  {
                    $set: true,
                },
                video: {
                    $set: action.payload.video,
                },
            });
        },
        close: (state) => {
            return update(state, {
                open: {
                    $set: false,
                },
            });
        },
    },
});

export const VideoOverlayActions = videoOverlaySlice.actions;

export const VideoOverlayReducer = videoOverlaySlice.reducer;

export default videoOverlaySlice;
