//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import Button       from '@stateless/atomic/Button';
import ButtonColor  from '@stateless/atomic/Button/ButtonColor';
import IconType     from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    backTextKey:         PropTypes.string,
    onBackButtonClicked: PropTypes.func,
};

const BackButton = ({
    backTextKey = 'backToOverview',
    onBackButtonClicked = DefaultTypes.func,
}) => {
    return (
        <Button
            onClick={onBackButtonClicked}
            className={styles.backButton}
            text={I18n.t(backTextKey)}
            color={ButtonColor.white}
            iconLeft={IconType.chevronLeft}
        />
    );
};

BackButton.propTypes = propTypes;

export default BackButton;
