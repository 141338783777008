//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api                  from '@api/index';
import SagaStateHelper           from '@helper/SagaStateHelper';
import { AlertBoxActions }       from '@slices/alertBox';
import { ContactRequestActions } from '@slices/contactRequest';

function* submitContactRequest(action) {
    const { subject, message } = yield SagaStateHelper.selectFromState('contactRequest');
    const contactRequest       = {
        subject,
        message,
    };
    const response             = yield call(Api.context.contactRequest.submitContactRequest, contactRequest);

    if (response.ok) {
        yield put(ContactRequestActions.submitContactRequestSucceeded());
        yield put(ContactRequestActions.resetContactRequest());
        yield put(AlertBoxActions.showSuccessAlertTranslated({
            textKey: 'contactForm.success',
        }));
    } else {
        yield put(ContactRequestActions.submitContactRequestFailed());
        yield put(AlertBoxActions.showErrorAlertTranslated({
            textKey: 'contactForm.error',
        }));
    }
}

export default {
    submitContactRequest,
};
