//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';
import { REHYDRATE }       from 'redux-persist';
import { all }             from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';
import { takeLeading }     from 'redux-saga/effects';
import { takeEvery }       from 'redux-saga/effects';

import { AlertBoxActions }              from '@slices/alertBox';
import { BrowserActions }               from '@slices/browser';
import { ContactRequestActions }        from '@slices/contactRequest';
import { LoadingActions }               from '@slices/loading';
import { NavigationActions }            from '@slices/navigation';
import { PreloadActions }               from '@slices/preload';
import { ShortclipsActions }            from '@slices/shortclips';
import { StagingAuthenticationActions } from '@slices/stagingAuthentication';
import { TutorialsActions }             from '@slices/tutorials';
import { UserActions }                  from '@slices/user';
import { VideosActions }                from '@slices/videos';

import AlertBoxSagas              from './alertBox';
import BrowserSagas               from './browser';
import ContactRequestSagas        from './contactRequest';
import LoadingSagas               from './loading';
import NavigationSagas            from './navigation';
import PreLoadSagas               from './preload';
import ShortclipsSagas            from './shortclips';
import StagingAuthenticationSagas from './stagingAuthentication';
import TutorialsSagas             from './tutorials';
import UserSagas                  from './user';
import VideosSagas                from './videos';

const callAlertBoxSagas = () => {
    return [
        // @formatter:off
        takeLatest([AlertBoxActions.showErrorAlertTranslated().type],   AlertBoxSagas.showErrorAlertTranslated),
        takeLatest([AlertBoxActions.showSuccessAlertTranslated().type], AlertBoxSagas.showSuccessAlertTranslated),
        // @formatter:on
    ];
};

const callBrowserSagas = () => {
    return [
        // @formatter:off
        takeLatest([BrowserActions.scrollToTop().type],              BrowserSagas.scrollToTop),
        takeLatest([BrowserActions.scrollToTopReactDataGrid().type], BrowserSagas.scrollToTopReactDataGrid),
        // @formatter:on
    ];
};

const callStagingAuthenticationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.resetState().type],              StagingAuthenticationSagas.reset),
        takeLatest([StagingAuthenticationActions.authenticate().type], StagingAuthenticationSagas.authenticate),
        // @formatter:on
    ];
};

const callUserSagas = () => {
    return [
        // @formatter:off
        takeLatest([UserActions.fetchUser().type],            UserSagas.fetchUser),
        takeLatest([UserActions.login().type],                UserSagas.login),
        takeLatest([UserActions.loginWithCredentials().type], UserSagas.loginWithCredentials),
        takeLatest([UserActions.loginFailed().type],          UserSagas.loginFailed),
        takeEvery([UserActions.loginSucceeded().type],        UserSagas.loginSucceeded),
        takeLatest([UserActions.tryRestoreToken().type],      UserSagas.restoreToken),
        takeLatest([UserActions.logout().type],               UserSagas.logout),
        takeLatest([UserActions.saveBusinessCardText().type], UserSagas.saveBusinessCardText),
        takeLatest([NavigationActions.resetState().type],     UserSagas.logout),
        // @formatter:on
    ];
};

const callLoadingSagas = () => {
    return [
        // @formatter:off
        takeLatest([LoadingActions.overlayClicked().type], LoadingSagas.overlayClicked),
        // @formatter:on
    ];
};

const callNavigationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationActions.redirect().type],     NavigationSagas.redirect),
        takeLatest([NavigationActions.replaceRoute().type], NavigationSagas.replaceRoute),
        // @formatter:on
    ];
};

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], LoadingSagas.resetClickCount),
        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE],                                         LoadingSagas.rehydrate),
        takeLatest([REHYDRATE],                                         PreLoadSagas.preLoadData),
        takeLatest([PreloadActions.preLoadSessionDependingData().type], PreLoadSagas.preLoadSessionDependingData),
        // @formatter:on
    ];
};

const callVideosSagas = () => {
    return [
        // @formatter:off
        takeLatest([VideosActions.fetchAboutUsVideo().type], VideosSagas.fetchAboutUsVideo),
        takeLatest([VideosActions.fetchVideos().type],       VideosSagas.fetchVideos),
        takeLeading([VideosActions.downloadVideoZip().type], VideosSagas.downloadVideoZip),
        // @formatter:on
    ];
};

const callShortclipsSagas = () => {
    return [
        // @formatter:off
        takeLatest([ShortclipsActions.fetchShortclips().type], ShortclipsSagas.fetchShortclips),
        // @formatter:on
    ];
};

const callTutorialsSagas = () => {
    return [
        // @formatter:off
        takeLatest([TutorialsActions.fetchTutorials().type], TutorialsSagas.fetchTutorials),
        // @formatter:on
    ];
};

const callContactRequestSagas = () => {
    return [
        // @formatter:off
        takeLatest([ContactRequestActions.submitContactRequest().type], ContactRequestSagas.submitContactRequest),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        ...callAlertBoxSagas(),
        ...callUserSagas(),
        ...callLoadingSagas(),
        ...callVideosSagas(),
        ...callShortclipsSagas(),
        ...callTutorialsSagas(),
        ...callRehydrateSagas(),
        ...callContactRequestSagas(),
        ...callStagingAuthenticationSagas(),
        ...callNavigationSagas(),
        ...callLocationChangeSagas(),
        ...callBrowserSagas(),
    ]);
}

export default {
    root,
};
