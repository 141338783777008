//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useContactRequestActions }        from '@slices/contactRequest';
import ClipspotsContainerList              from '@stateless/composed/ClipspotsContainerList';
import ContactForm                         from '@stateless/composed/ContactForm';
import DownloadableVideoClipspotsContainer from '@stateless/composed/DownloadableVideoClipspotsContainer';
import { selectAboutUsVideo }              from '@store/selectors/videos';

const AboutUs = () => {
    const aboutUsVideo          = useSelector(selectAboutUsVideo);
    const dispatch              = useDispatch();
    const contactRequestActions = useContactRequestActions(dispatch);

    function onSendMessageButtonClick(subject, message) {
        contactRequestActions.submitContactRequest({
            subject,
            message,
        });
        return true;
    }

    function renderAboutUsContainer() {
        return (
            <div>
                <DownloadableVideoClipspotsContainer
                    video={aboutUsVideo}
                    headline={I18n.t('aboutUs.thisIsUs')}
                    downloadButtonText={I18n.t('aboutUs.downloadExplanationVideo')}
                />
            </div>
        );
    }

    function renderContactForm() {
        return (
            <div>
                <ContactForm sendMessage={onSendMessageButtonClick} />
            </div>
        );
    }

    return (
        <ClipspotsContainerList
            clipspotsContainers={[
                renderAboutUsContainer(),
                renderContactForm(),
            ]}
        />
    );
};
export default AboutUs;
