//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useUser } from '@slices/user';

const LogoutScreen = () => {
    const dispatch    = useDispatch();
    const userActions = useUser(dispatch);

    useEffect(
        () => {
            userActions.logout();
        },
        [],
    );
};

export default LogoutScreen;
