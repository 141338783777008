//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Routes from '@constants/Routes';

export default Object.freeze({
    [Routes.login]:      {
        lowerLeftLogo:       true,
        upperLeftLogo:       true,
        bottomNavigationBar: false,
        sideNavigationBar:   false,
    },
    [Routes.videoclips]: {
        headlineParams: {
            name: 'user.name',
        },
    },
});
