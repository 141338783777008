//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Url from 'url';

import _           from 'lodash';
import queryString from 'query-string';

import Paging from '@constants/PagingFields';

/**
 * @param iri
 */
const getIdFromIri = (iri) => {
    const splittedIri = iri.split('/');

    return splittedIri[3];
};

/**
 * @param array
 * @param id
 * @returns {unknown}
 */
const findById = (array, id) => {
    return _.find(array, (possibleEntry) => getIdFromIri(possibleEntry.iri) === id);
};

/**
 * @param array
 * @param iri
 * @returns {unknown}
 */
const findByIri = (array, iri) => {
    return _.find(array, (possibleEntry) => possibleEntry.iri === iri);
};

/**
 * @param key
 * @param objectToClean
 */
const removeLeadingHydraFromKey = (key, objectToClean) => {
    let newKey             = key;
    const newObjectToClean = _.clone(objectToClean);

    if (newKey.indexOf('hydra:') === 0) {
        newObjectToClean[newKey.substr(6)] = newObjectToClean[newKey];

        delete newObjectToClean[newKey];

        newKey = newKey.substr(6);
    }

    if (
        typeof newObjectToClean[newKey] === 'string' &&
        newObjectToClean[newKey].indexOf('hydra:') === 0
    ) {
        newObjectToClean[newKey] = newObjectToClean[newKey].substr(6);
    }

    return [newKey, newObjectToClean];
};

/**
 *
 * @param key
 * @param objectToClean
 */
const removeHydraSearch = (key, objectToClean) => {
    const newObjectToClean = objectToClean;
    const keysToRemoved    = [
        'context',
        'search',
    ];

    if (keysToRemoved.includes(key)) {
        delete newObjectToClean[key];
    }

    return newObjectToClean;
};

/**
 * @param key
 * @param objectToClean
 */
const mapHydraIdToIri = (key, objectToClean) => {
    const newObjectToClean = _.clone(objectToClean);

    if (key === '@id') {
        const iriId          = newObjectToClean[key];
        newObjectToClean.iri = iriId;
        newObjectToClean.id  = _.split(iriId, '/').pop();
    }

    return newObjectToClean;
};

const isHydraResponse = (responseData) => {
    return _.keysIn(responseData).includes('@context');
};

const removeHydraFromObject = (objectToClean) => {
    let newObjectToClean = _.clone(objectToClean);
    let keys             = Object.keys(objectToClean);

    if (!keys) {
        keys = {};
    }

    for (let key of keys) {
        if (newObjectToClean[key]) {
            [key, newObjectToClean] = removeLeadingHydraFromKey(key, newObjectToClean);

            newObjectToClean = removeHydraSearch(key, newObjectToClean);

            if (_.isArray(newObjectToClean[key])) {
                const arrayOfChild    = _.clone(newObjectToClean[key]);
                newObjectToClean[key] = [];

                for (const childObjectToClean of arrayOfChild) {
                    const cleanedChild = removeHydraFromObject(childObjectToClean);

                    newObjectToClean[key].push(cleanedChild);
                }
            } else if (_.isObject(newObjectToClean[key])) {
                newObjectToClean[key] = removeHydraFromObject(newObjectToClean[key]);
            }

            newObjectToClean = mapHydraIdToIri(key, newObjectToClean);
        }
    }

    return newObjectToClean;
};

const getPageFromIri = (iri) => {
    if (!iri) {
        return null;
    }

    if (_.isNumber(iri)) {
        return iri;
    }

    const params    = queryString.parse(Url.parse(iri).query);
    const pageParam = _.get(params, 'page', 1);
    const page      = _.toNumber(pageParam);

    return page;
};

const getMembersFromResponse = (response) => {
    const cleanedResponse = removeHydraFromObject(response);

    if (_.has(
        cleanedResponse,
        [
            'member',
        ],
    )) {
        return _.get(
            cleanedResponse,
            [
                'member',
            ],
        );
    }

    return [];
};

const getMembersAndPaginationFromResponse = (response, itemsPerPage, currentPageFallback) => {
    const members            = getMembersFromResponse(response);
    const totalItems         = _.get(response, 'totalItems');
    const hydraView          = _.get(response, 'view');
    const calculatedLastPage = Math.ceil(totalItems / itemsPerPage);
    let lastPage             = getPageFromIri(_.get(hydraView, 'last', calculatedLastPage));
    let firstPage            = getPageFromIri(_.get(hydraView, 'first', 1));
    let currentPage          = getPageFromIri(_.get(hydraView, 'iri', currentPageFallback));
    const calculatedNextPage = _.min([currentPage + 1, calculatedLastPage]);
    const nextPage           = getPageFromIri(_.get(hydraView, 'next', calculatedNextPage));
    const previousPage       = (
        firstPage < currentPage ?
            currentPage - 1 :
            null
    );

    if (!totalItems) {
        lastPage    = 0;
        currentPage = 0;
        firstPage   = 0;
    }

    const pagingObject = {
        [Paging.totalItems]:   totalItems,
        [Paging.itemsPerPage]: itemsPerPage,
        [Paging.firstPage]:    firstPage,
        [Paging.lastPage]:     lastPage,
        [Paging.currentPage]:  currentPage,
        [Paging.nextPage]:     nextPage,
        [Paging.previousPage]: previousPage,
    };

    return [
        members,
        pagingObject,
    ];
};

export default {
    getPageFromIri,
    findById,
    findByIri,
    getIdFromIri,
    getMembersAndPaginationFromResponse,
    getMembersFromResponse,
    isHydraResponse,
    mapHydraIdToIri,
    removeHydraFromObject,
    removeHydraSearch,
    removeLeadingHydraFromKey,
};
