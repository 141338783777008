//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import I18n            from 'i18next';
import _               from 'lodash';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes                    from '@components/PropTypes';
import { useAlertBox }              from '@slices/alertBox';
import { useContactRequestActions } from '@slices/contactRequest';
import ClipspotsContainer           from '@stateless/atomic/ClipspotsContainer';
import ClipspotsContainerSize       from '@stateless/atomic/ClipspotsContainer/ClipspotsContainerSize';
import IconType                     from '@stateless/atomic/Icon/IconType';
import TextInput                    from '@stateless/atomic/TextInput';
import ApproveDataPrivacy           from '@stateless/composed/ContactForm/ApproveDataPrivacy';
import { selectSubject }            from '@store/selectors/contactRequest';
import { selectMessage }            from '@store/selectors/contactRequest';

const propTypes = {
    sendMessage: PropTypes.func,
};

const ContactForm = ({
    sendMessage = _.noop,
}) => {
    const dispatch                                      = useDispatch();
    const alertBoxActions                               = useAlertBox(dispatch);
    const contactRequests                               = useContactRequestActions(dispatch);
    const setMessage                                    = contactRequests.setMessage;
    const setSubject                                    = contactRequests.setSubject;
    const message                                       = useSelector(selectMessage);
    const subject                                       = useSelector(selectSubject);
    const [dataPrivacyApproved, setDataPrivacyApproved] = useState(false);

    function updateValue(setter) {
        return (event) => {
            setter(event.target.value);
        };
    }

    function alertErrorMessage(error) {
        alertBoxActions.showErrorAlertTranslated({
            textKey: `contactForm.errors.${error}`,
        });
    }

    const onSendMessageButtonClick = () => {
        if (!subject) {
            alertErrorMessage('subjectMissing');

            return;
        }

        if (!message) {
            alertErrorMessage('messageMissing');

            return;
        }

        if (subject?.length < 3) {
            alertErrorMessage('subjectTooShort');

            return;
        }

        if (message?.length < 10) {
            alertErrorMessage('messageTooShort');

            return;
        }

        if (!dataPrivacyApproved) {
            alertErrorMessage('dataPrivacyNotApproved');

            return;
        }

        sendMessage(subject, message);
        setDataPrivacyApproved(false);
    };

    function renderApproveDataPrivacyContainer() {
        return (
            <ApproveDataPrivacy
                checked={dataPrivacyApproved}
                onChange={setDataPrivacyApproved}
            />
        );
    }

    return (
        <ClipspotsContainer
            size={ClipspotsContainerSize.wide}
            headline={I18n.t('contactForm.headline')}
            buttonProps={{
                text:      I18n.t('contactForm.sendMessage'),
                iconRight: IconType.share,
                onClick:   onSendMessageButtonClick,
            }}
            buttonSiblings={renderApproveDataPrivacyContainer()}
        >
            <TextInput
                placeholderText={I18n.t('contactForm.subject')}
                value={subject}
                onChange={updateValue(setSubject)}
            />
            <TextInput
                placeholderText={I18n.t('contactForm.message')}
                multiline={true}
                value={message}
                onChange={updateValue(setMessage)}
            />
        </ClipspotsContainer>
    );
};

ContactForm.propTypes = propTypes;

export default ContactForm;
