//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = Object.freeze({
    aboutUs:                    {
        title:                    'Über ClipSpots',
        headline:                 'Über ClipSpots',
        subtitle:                 'ClipSpots erklärt ClipSpots.',
        thisIsUs:                 'Das sind wir.',
        downloadExplanationVideo: 'Erklärvideo herunterladen',
    },
    address:                    'Anschrift',
    and:                        'und',
    backToOverview:             'Zurück zur Übersicht',
    backToAboutUs:              'Zurück zu "Über uns"',
    businessCard:               {
        title:                 'Visitenkarte',
        headline:              'Visitenkarte',
        subtitle:              'Ihre persönlichen Informationen.',
        logoAnimation:         'Logoanimation',
        downloadLogoAnimation: 'Logoanimation herunterladen',
        saveSuccess:           'Ihre Visitenkarte wurde erfolgreich gespeichert.',
        saveError:             'Ihre Visitenkarte konnte nicht gespeichert werden.',
    },
    contactForm:                {
        headline:           'Kontaktformular',
        sendMessage:        'Nachricht senden',
        subject:            'Betreff',
        message:            'Ihre Nachricht...',
        dataPrivacy:        'Datenschutzerklärung',
        approveDataPrivacy: 'Ich willige die {dataPrivacyLink} ein.',
        success:            'Ihre Nachricht wurde erfolgreich versendet.',
        error:              'Ihre Nachricht konnte nicht versendet werden.',
        errors:             {
            subjectMissing:         'Bitte geben Sie einen Betreff ein.',
            messageMissing:         'Bitte geben Sie eine Nachricht ein.',
            subjectTooShort:        'Der Betreff ist zu kurz.',
            messageTooShort:        'Die Nachricht ist zu kurz.',
            dataPrivacyNotApproved: 'Bitte akzeptieren Sie die Datenschutzerklärung.',
        },
    },
    dataPrivacy:                {
        title:             'Datenschutz',
        contactMailTarget: 'info@clipspots.de',
    },
    edit:                       'Bearbeiten',
    loadingAboutUsVideo:        'Erklärvideo wird geladen',
    loading:                    'Lade...',
    loadingLogin:               'Prüfe Zugangsdaten...',
    loadingUser:                'Benutzer wird geladen',
    loadingVideos:              'Videos werden geladen',
    login:                      {
        emailAddress: 'E-Mail Adresse',
        loginButton:  'Anmelden',
        password:     'Passwort',
        enterData:    'Geben Sie Ihre Log-In Daten ein.',
        title:        'Login',
        welcome:      'Willkommen!',
        errorTitle:   'Leider falsch.',
        errorText:    'Bitte versuchen Sie es erneut.',
    },
    imprint:                    {
        title:        'Impressum',
        contactTitle: 'ClipSpots',
        phone:        '+49 (0) 1512 - 62 02 324',
        email:        'info@clipspots.de',
        website:      'www.clipspots.de',
    },
    logout:                     'Logout',
    more:                       'Mehr',
    navigationBar:              {
        aboutUs:      'Über uns',
        businessCard: 'Visitenkarte',
        shortclips:   'Kurzclips',
        tutorials:    'Tutorials',
        videoclips:   'Videoclips',
    },
    no:                         'Nein',
    oclock:                     'Uhr',
    or:                         'oder',
    ok:                         'OK',
    pageContainer:              {
        backgroundLogoAlt: 'Hintergrund Logo',
        lowerLeftLogoAlt:  'Gedrehtes Logo',
        upperLeftLogoAlt:  'Clipspots Logo',
    },
    pageTitle:                  'Clipspots',
    pageNotFound:               {
        title:    'Seite nicht gefunden',
        headline: '404 - Seite nicht gefunden',
        subtitle: 'Die angeforderte Seite konnte nicht gefunden werden. Bitte überprüfen Sie die URL und versuchen Sie es erneut.',
    },
    savingBusinessCardText:     'Anschrift wird gespeichert',
    screenTooSmallText:         'Ihre aktuelle Browsergröße wird derzeit nicht unterstützt. Bitte vergrößern Sie dieses Fenster.',
    screenTooSmallTitle:        'Ihr Display oder Browserfenster ist zu klein',
    stagingPasswordPlaceholder: 'Staging-Passwort',
    shortclip:                  {
        detail: {
            download:        'Video herunterladen',
            downloadPreview: 'Vorschaubild herunterladen',
            downloadText:    'Text herunterladen',
            downloadAll:     'Alles herunterladen',
        },
    },
    shortClips:                 {
        title:    'Kurzclips',
        headline: 'Kurzclips',
        subtitle: 'Und hier finden Sie die etwas kürzeren Clips.',
    },
    submittingContactRequest:   'Nachricht wird gesendet',
    tutorials:                  {
        title:    'Tutorials',
        headline: 'Tutorials',
        subtitle: 'Wir zeigen wie\'s geht.',
    },
    tutorial:                   {
        detail: {
            download: 'Erklärclip herunterladen',
        },
    },
    versionNumberDateFormat:    'DD.MM.YYYY HH:mm:ss',
    video:                      {
        detail: {
            download:        'Video herunterladen',
            downloadPreview: 'Vorschaubild herunterladen',
            downloadText:    'Text herunterladen',
            downloadAll:     'Alles herunterladen',
        },
    },
    videoclips:                 {
        title:    'Videoclips',
        headline: 'Hallo, {{name}}!',
        subtitle: 'Hier finden Sie Ihre Videoclips.',
    },
    videoNotSupported:          'Ihr Browser unterstützt das Video nicht.',
    yes:                        'Ja',
});

export default german;
