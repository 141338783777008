// _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import IconAbout        from '@assets/icons/about.svg';
import IconBusinessCard from '@assets/icons/businessCard.svg';
import IconCheck        from '@assets/icons/check.svg';
import IconChevronLeft  from '@assets/icons/chevronLeft.svg';
import IconChevronRight from '@assets/icons/chevronRight.svg';
import IconCross        from '@assets/icons/cross.svg';
import IconDownload     from '@assets/icons/download.svg';
import IconEdit         from '@assets/icons/edit.svg';
import IconGlobe        from '@assets/icons/globe.svg';
import IconLogout       from '@assets/icons/logout.svg';
import IconMail         from '@assets/icons/mail.svg';
import IconMenu         from '@assets/icons/menu.svg';
import IconPause        from '@assets/icons/pause.svg';
import IconPhone        from '@assets/icons/phone.svg';
import IconPlayButton   from '@assets/icons/playButton.svg';
import IconShare        from '@assets/icons/share.svg';
import IconShortClip    from '@assets/icons/shortclip.svg';
import IconTutorials    from '@assets/icons/tutorials.svg';
import IconVideoClips   from '@assets/icons/videoclips.svg';
import PropTypes        from '@components/PropTypes';
import IconType         from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    className: PropTypes.string,
    iconType:  PropTypes.iconType,
    onClick:   PropTypes.func,
};

const Icon = ({
    className,
    iconType = null,
    onClick,
}) => {
    function renderSvg() {
        const iconMapping = {
            [IconType.about]:        <IconAbout />,
            [IconType.businessCard]: <IconBusinessCard />,
            [IconType.check]:        <IconCheck />,
            [IconType.chevronLeft]:  <IconChevronLeft />,
            [IconType.chevronRight]: <IconChevronRight />,
            [IconType.cross]:        <IconCross />,
            [IconType.download]:     <IconDownload />,
            [IconType.edit]:         <IconEdit />,
            [IconType.globe]:        <IconGlobe />,
            [IconType.logout]:       <IconLogout />,
            [IconType.mail]:         <IconMail />,
            [IconType.menu]:         <IconMenu />,
            [IconType.pause]:        <IconPause />,
            [IconType.phone]:        <IconPhone />,
            [IconType.playButton]:   <IconPlayButton />,
            [IconType.share]:        <IconShare />,
            [IconType.shortclip]:    <IconShortClip />,
            [IconType.tutorials]:    <IconTutorials />,
            [IconType.videoclips]:   <IconVideoClips />,
        };

        return iconMapping[iconType];
    }

    return (
        <span
            className={classNames(
                styles.icon,
                className,
            )}
            onClick={onClick}
        >
            {renderSvg()}
        </span>
    );
};

Icon.propTypes = propTypes;

export default Icon;
