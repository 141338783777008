//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n            from 'i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useUser }                         from '@slices/user';
import ClipspotsContainer                  from '@stateless/atomic/ClipspotsContainer';
import IconType                            from '@stateless/atomic/Icon/IconType';
import ClipspotsContainerList              from '@stateless/composed/ClipspotsContainerList';
import DownloadableVideoClipspotsContainer from '@stateless/composed/DownloadableVideoClipspotsContainer';
import EditableText                        from '@stateless/composed/EditableText';
import { selectBusinessCardText }          from '@store/selectors/user';
import { selectLogoAnimation }             from '@store/selectors/user';

const BusinessCard = () => {
    const businessCardText = useSelector(selectBusinessCardText);
    const logoAnimation    = useSelector(selectLogoAnimation);
    const dispatch         = useDispatch();
    const userActions      = useUser(dispatch);

    function saveBusinessCardText(text) {
        // TODO https://lulububu.atlassian.net/browse/CLIPSPOTS-457
        userActions.saveBusinessCardText({
            businessCardText: text,
        });
    }

    function renderLogoAnimation() {
        return (
            <div>
                <DownloadableVideoClipspotsContainer
                    video={logoAnimation}
                    headline={I18n.t('businessCard.logoAnimation')}
                    downloadButtonText={I18n.t('businessCard.downloadLogoAnimation')}
                />
            </div>
        );
    }

    function renderAddress() {
        return (
            <div>
                <ClipspotsContainer
                    headline={I18n.t('address')}
                    buttonProps={{
                        text:      I18n.t('edit'),
                        iconRight: IconType.edit,
                    }}
                >
                    <EditableText
                        save={saveBusinessCardText}
                        text={businessCardText}
                    />
                </ClipspotsContainer>
            </div>
        );
    }

    return (
        <ClipspotsContainerList
            clipspotsContainers={[
                renderLogoAnimation(),
                renderAddress(),
            ]}
        />
    );
};
export default BusinessCard;
