//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import PropTypes               from '@components/PropTypes';
import VideoClipspotsContainer from '@stateless/composed/VideoClipspotsContainer';

import styles from './styles.module.scss';

const propTypes = {
    className:           PropTypes.string,
    clipspotsContainers: PropTypes.arrayOf(PropTypes.clipspotsContainer),
    videoType:           PropTypes.videoType,
    videos:              PropTypes.arrayOf(PropTypes.object),
};

const ClipspotsContainerList = ({
    className,
    clipspotsContainers = [],
    videos = [],
    videoType = null,
}) => {
    function renderVideos() {
        return _.map(
            videos,
            (video) => <VideoClipspotsContainer
                video={video}
                videoType={videoType}
            />,
        );
    }

    return (
        <div
            className={classNames(
                styles.clipspotsContainerList,
                className,
            )}
        >
            {renderVideos()}
            {clipspotsContainers}
        </div>
    );
};

ClipspotsContainerList.propTypes = propTypes;

export default ClipspotsContainerList;
