//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _        from 'lodash';
import { put }  from 'redux-saga/effects';
import { call } from 'redux-saga/effects';

import * as Api          from '@api/index';
import Download          from '@helper/Download';
import HydraHelper       from '@helper/Hydra';
import { VideosActions } from '@slices/videos';

function* downloadVideoZip(action) {
    const { video } = action.payload;
    const response  = yield call(
        Api.context.videos.downloadVideoZip,
        video,
    );

    if (response.ok) {
        const data     = _.get(response, 'data', null);
        const title    = _.get(video, 'title', 'video');
        const fileName = title.replace(/[^a-z0-9]/gi, '_').replace(/_$/, '').toLowerCase();

        Download.downloadBlob(data, `${fileName}.zip`);
    }
}

function* fetchAboutUsVideo() {
    const response = yield call(Api.context.videos.fetchAboutUsVideo);

    if (response.ok) {
        const aboutUsVideo = _.get(HydraHelper.getMembersFromResponse(response.data), '[0]', null);

        yield put(VideosActions.fetchAboutUsVideoSucceeded({
            aboutUsVideo,
        }));
    } else {
        yield put(VideosActions.fetchAboutUsVideoFailed());
    }
}

function* fetchVideos() {
    const response = yield call(Api.context.videos.fetchVideos);

    if (response.ok) {
        const videos = HydraHelper.getMembersFromResponse(response.data);

        yield put(VideosActions.fetchVideosSucceeded({
            videos,
        }));
    } else {
        yield put(VideosActions.fetchVideosFailed());
    }
}

export default {
    downloadVideoZip,
    fetchAboutUsVideo,
    fetchVideos,
};
