//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ButtonColor from '@stateless/atomic/Button/ButtonColor';
import ButtonType  from '@stateless/atomic/Button/ButtonType';

class DefaultTypes {
    static func = () => {
        console.warn('Missing onClick function detected!');
    };

    static button = () => (
        {
            color:     ButtonColor.default,
            disabled:  false,
            iconLeft:  null,
            iconRight: null,
            onClick:   DefaultTypes.func,
            text:      null,
            type:      ButtonType.default,
        }
    );
}

export default Object.assign(DefaultTypes);
