//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    subject: null,
    message: null,
});

const contactRequestSlice = createSlice({
    name:     'contactRequest',
    initialState,
    reducers: {
        setSubject:                    (state, action) => {
            const { payload } = action;

            return update(state, {
                subject: {
                    $set: payload,
                },
            });
        },
        setMessage:                    (state, action) => {
            const { payload } = action;

            return update(state, {
                message: {
                    $set: payload,
                },
            });
        },
        submitContactRequest:          LoadingLevelHelper.increaseLoading(() => {
        }, I18n.t('submittingContactRequest')),
        submitContactRequestFailed:    LoadingLevelHelper.decreaseLoading(() => {
        }),
        submitContactRequestSucceeded: LoadingLevelHelper.decreaseLoading(() => {
        }),
        resetContactRequest:           (state) => {
            return update(state, {
                $set: initialState,
            });
        },
    },
});

export const ContactRequestActions = contactRequestSlice.actions;

export const ContactRequestReducer = contactRequestSlice.reducer;

export const useContactRequestActions = (dispatch) => bindActionCreators(ContactRequestActions, dispatch);

export default contactRequestSlice;
