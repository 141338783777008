//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

const userApi = (api) => {
    return {
        submitContactRequest: (
            subject,
            message,
        ) => {
            return api.post(
                ApiUrls.API_CONTACT_REQUESTS_URL,
                {
                    subject,
                    message,
                },
            );
        },
        fetchUser:            (userIri) => {
            return api.get(userIri);
        },
        saveBusinessCardText: (userIri, user) => {
            return api.put(userIri, user);
        },
        login:                (
            email,
            password,
        ) => {
            return api.post(
                ApiUrls.API_TOKENS,
                {
                    email,
                    password,
                },
            );
        },
        removeToken:          () => {
            /* eslint-disable no-param-reassign */
            /* Not possible to disable this here since we actually have to remove this in the api object */
            delete api.headers.Authorization;
        },
        setToken:             (token) => {
            console.log('API: setToken', token);

            if (token) {
                const authorization = `Bearer ${token}`;

                api.setHeader('Authorization', authorization);
            } else {
                userApi(api).removeToken();
            }
        },
    };
};

export default (api) => userApi(api);
