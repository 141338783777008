//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';

import PropTypes          from '@components/PropTypes';
import Button             from '@stateless/atomic/Button';
import ClipspotsContainer from '@stateless/atomic/ClipspotsContainer';
import Icon               from '@stateless/atomic/Icon';
import IconType           from '@stateless/atomic/Icon/IconType';

import AlertBoxType from './AlertBoxType';
import styles       from './styles.module.scss';

const propTypes = {
    onCloseButtonClick: PropTypes.func,
    text:               PropTypes.string,
    title:              PropTypes.string,
    type:               PropTypes.oneOfObjectValues(AlertBoxType),
};

const AlertBox = ({
    onCloseButtonClick = null,
    text = '',
    title = '',
    type = null,
}) => {
    function renderCloseButton() {
        if (onCloseButtonClick) {
            return (
                <span
                    className={styles.alertBoxCloseButtonWrapper}
                    onClick={onCloseButtonClick}
                >
                    <Icon iconType={IconType.cross} />
                </span>
            );
        }

        return null;
    }

    const alertBoxTypeToIconStyle = {
        [AlertBoxType.error]:   styles.alertBoxError,
        [AlertBoxType.success]: styles.alertBoxSuccess,
    };

    return (
        <ClipspotsContainer
            className={classNames(
                styles.alertBox,
                alertBoxTypeToIconStyle[type],
            )}
            headline={title}
            subtitle={text}
        >
            {renderCloseButton()}
            <Button
                text={I18n.t('ok')}
                onClick={onCloseButtonClick}
            />
        </ClipspotsContainer>
    );
};

AlertBox.propTypes = propTypes;

export default AlertBox;
