//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n    from 'i18next';
import _       from 'lodash';
import { put } from 'redux-saga/effects';

import { AlertBoxActions } from '@slices/alertBox';

function* showErrorAlertTranslated(action) {
    const payload = _.get(action, 'payload', {});

    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(payload.textKey),
    }));
}

function* showSuccessAlertTranslated(action) {
    const payload = _.get(action, 'payload', {});

    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t(payload.textKey),
    }));
}

export default {
    showErrorAlertTranslated,
    showSuccessAlertTranslated,
};
