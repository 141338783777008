//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';

const initialState = Object.freeze({});

const navigationSlice = createSlice({
    name:     'navigation',
    initialState,
    reducers: {
        replaceRoute: (state, action) => {
        },
        redirect:     (state, action) => {
        },
        resetState:   (state, action) => {
        },
    },
});

export const NavigationActions = navigationSlice.actions;

export const NavigationReducer = navigationSlice.reducer;

export default navigationSlice;
