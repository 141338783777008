//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    shortclips: [],
});

const shortclipsSlice = createSlice({
    name:     'shortclips',
    initialState,
    reducers: {
        fetchShortclips:          LoadingLevelHelper.increaseLoading((state, action) => {
        }),
        fetchShortclipsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                shortclips: {
                    $set: action.payload.shortclips,
                },
            });
        }),
        fetchShortclipsFailed:    LoadingLevelHelper.decreaseLoading((state, action) => {
        }),
    },
});

export const ShortclipsActions = shortclipsSlice.actions;

export const ShortclipsReducer = shortclipsSlice.reducer;

export default shortclipsSlice;
