//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { PropTypes as BasePropTypes } from 'prop-types';

import { TestIdPrefixes }     from '@constants/TestIds';
import { TestIds }            from '@constants/TestIds';
import VideoType              from '@constants/VideoType';
import ButtonColor            from '@stateless/atomic/Button/ButtonColor';
import ButtonType             from '@stateless/atomic/Button/ButtonType';
import ClipspotsContainerSize from '@stateless/atomic/ClipspotsContainer/ClipspotsContainerSize';
import HeadlineType           from '@stateless/atomic/Headline/HeadlineType';
import IconType               from '@stateless/atomic/Icon/IconType';

class PropTypes {
    static cssUnits = [
        BasePropTypes.number,
        BasePropTypes.string,
    ];

    static children = BasePropTypes.oneOfType([
        BasePropTypes.arrayOf(BasePropTypes.node),
        BasePropTypes.node,
    ]);

    static cssHeight = BasePropTypes.oneOfType(PropTypes.cssUnits);

    static cssWidth = BasePropTypes.oneOfType(PropTypes.cssUnits);

    static date = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.instanceOf(Date),
        BasePropTypes.number,
    ]);

    static image = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.object,
    ]);

    static numberOrString = BasePropTypes.oneOfType([
        BasePropTypes.string,
        BasePropTypes.number,
    ]);

    static navigationBarEntry = BasePropTypes.shape({
        title: BasePropTypes.string,
        icon:  BasePropTypes.string,
        route: BasePropTypes.string,
    });

    static button = BasePropTypes.shape({
        color:     BasePropTypes.buttonColor,
        disabled:  BasePropTypes.bool,
        iconLeft:  BasePropTypes.iconType,
        iconRight: BasePropTypes.iconType,
        onClick:   BasePropTypes.func,
        text:      BasePropTypes.string.isRequired,
        type:      BasePropTypes.buttonType,
    });

    static oneOfObjectKeys = (object) => {
        return BasePropTypes.oneOf(Object.keys(object));
    };

    static oneOfObjectValues = (object) => {
        return BasePropTypes.oneOf(Object.values(object));
    };

    static oneOfTestIds = this.oneOfObjectValues(TestIds);

    static oneOfTestIdPrefix = this.oneOfObjectValues(TestIdPrefixes);

    static buttonType = this.oneOfObjectValues(ButtonType);

    static buttonColor = this.oneOfObjectValues(ButtonColor);

    static headlineType = this.oneOfObjectValues(HeadlineType);

    static iconType = this.oneOfObjectKeys(IconType);

    static clipspotsContainerSize = this.oneOfObjectKeys(ClipspotsContainerSize);

    static clipspotsContainer = BasePropTypes.shape({
        buttonProps:    BasePropTypes.button,
        buttonSiblings: BasePropTypes.node,
        children:       BasePropTypes.children,
        className:      BasePropTypes.string,
        headline:       BasePropTypes.string,
        image:          BasePropTypes.string,
        onClick:        BasePropTypes.func,
        onImageClick:   BasePropTypes.func,
        size:           PropTypes.clipspotsContainerSize,
        subtitle:       BasePropTypes.string,
    });

    static videoType = this.oneOfObjectValues(VideoType);
}

export default Object.assign(PropTypes, BasePropTypes);
