//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    about:        'about',
    businessCard: 'businessCard',
    check:        'check',
    chevronLeft:  'chevronLeft',
    chevronRight: 'chevronRight',
    cross:        'cross',
    download:     'download',
    edit:         'edit',
    globe:        'globe',
    logout:       'logout',
    mail:         'mail',
    menu:         'menu',
    pause:        'pause',
    phone:        'phone',
    playButton:   'playButton',
    share:        'share',
    shortclip:    'shortclip',
    tutorials:    'tutorials',
    videoclips:   'videoclips',
});
