import React         from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes   from '@components/PropTypes';
import Icon        from '@stateless/atomic/Icon';
import IconType    from '@stateless/atomic/Icon/IconType';
import ProgressBar from '@stateless/atomic/ProgressBar';

import styles from './styles.module.scss';

const propTypes = {
    children: PropTypes.node,
    headline: PropTypes.string.isRequired,
    onClose:  PropTypes.func.isRequired,
    videoSrc: PropTypes.string.isRequired,
};

const VideoOverlay = ({ headline, videoSrc, children, onClose }) => {
    const videoRef                = useRef(null);
    const [isPlaying, setPlaying] = useState(true);
    const [progress, setProgress] = useState(0);
    const playPauseIconType       = (
        isPlaying
            ? IconType.pause
            : IconType.playButton
    );

    useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement) {
            videoElement.play();
            videoElement.ontimeupdate = () => {
                setProgress((
                    videoElement.currentTime / videoElement.duration
                ) * 100);
            };
        }

        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handlePlayPauseClick = (event) => {
        event.stopPropagation();

        const videoElement = videoRef.current;

        if (videoElement.paused) {
            videoElement.play();
            setPlaying(true);
        } else {
            videoElement.pause();
            setPlaying(false);
        }
    };

    function handleCloseClick(event) {
        event.stopPropagation();
        onClose();
    }

    function onVideoOverlayClick(event) {
        event.stopPropagation();
    }

    function onVideoContentClick(event) {
        event.stopPropagation();
        handlePlayPauseClick(event);
    }

    function onProgressChange(newProgress) {
        const videoElement = videoRef.current;
        const duration     = videoElement?.duration;
        const newTime      = (
            newProgress / 100
        ) * duration;

        if (!_.isNaN(newTime)) {
            videoElement.currentTime = newTime;

            setProgress(newProgress);
        }
    }

    return (
        <div
            className={styles.videoOverlay}
            onClick={onVideoOverlayClick}
        >
            <div
                className={styles.contentOverlay}
                onClick={onVideoContentClick}
            >
                <video
                    ref={videoRef}
                    className={styles.videoBackground}
                    loop
                    autoPlay
                >
                    <source
                        src={videoSrc}
                        type="video/mp4"
                    />
                    {I18n.t('videoNotSupported')}
                </video>
                <span className={styles.headline}>
                    {headline}
                </span>
                <Icon
                    className={styles.closeButton}
                    iconType={IconType.cross}
                    onClick={handleCloseClick}
                />
                <Icon
                    className={styles.playPauseButton}
                    iconType={playPauseIconType}
                    onClick={handlePlayPauseClick}
                />
                <ProgressBar
                    onProgressChange={onProgressChange}
                    progress={progress}
                />
                {children}
            </div>
        </div>
    );
};

VideoOverlay.propTypes = propTypes;

export default VideoOverlay;
