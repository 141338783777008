//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

export const selectOpen = (state) => {
    return _.get(state, ['videoOverlay', 'open']);
};

export const selectHeadline = (state) => {
    return _.get(state, ['videoOverlay', 'headline']);
};

export const selectVideo = (state) => {
    return _.get(state, ['videoOverlay', 'video']);
};
