//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Routes from '@constants/Routes';

const footerEntries = {
    [Routes.aboutUs]: [
        {
            title: I18n.t('imprint.title'),
            route: Routes.imprint,
        },
        {
            title: I18n.t('dataPrivacy.title'),
            route: Routes.dataPrivacy,
        },
    ],
};

export default {
    footerEntries,
};
