//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useLocation } from 'react-router';

import NavigationBar     from '@stateless/composed/NavigationBar';
import NavigationBarType from '@stateless/composed/NavigationBar/NavigationBarType';

const propTypes = {};

const BottomNavigationBar = () => {
    const location = useLocation();

    return (
        <NavigationBar
            type={NavigationBarType.bottom}
            currentRoute={location.pathname}
        />
    );
};

BottomNavigationBar.propTypes = propTypes;

export default BottomNavigationBar;
