//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import ButtonColor  from '@stateless/atomic/Button/ButtonColor';
import ButtonType   from '@stateless/atomic/Button/ButtonType';
import Icon         from '@stateless/atomic/Icon';

import styles from './styles.module.scss';

const propTypes = PropTypes.button;

const Button = ({
    className,
    color,
    disabled,
    iconLeft,
    iconRight,
    onClick,
    text,
    type,
} = DefaultTypes.button()) => {
    const [pressed, setPressed] = React.useState(false);

    function renderIconLeft() {
        if (iconLeft) {
            return (
                <div
                    className={classNames(
                        styles.iconContainer,
                        styles.iconContainerLeft,
                    )}
                >
                    <Icon iconType={iconLeft} />
                </div>
            );
        }

        return null;
    }

    function renderIconRight() {
        if (iconRight) {
            return (
                <div
                    className={classNames(
                        styles.iconContainer,
                        styles.iconContainerRight,
                    )}
                >
                    <Icon iconType={iconRight} />
                </div>
            );
        }

        return null;
    }

    function onMouseDown(event) {
        setPressed(true);
    }

    function onMouseUp(event) {
        setPressed(false);
    }

    return (
        <button
            className={classNames(
                className,
                styles.button,
                {
                    [styles.pressed]:   pressed,
                    [styles.iconOnly]:  !text,
                    [styles.large]:     type === ButtonType.large,
                    [styles.white]:     color === ButtonColor.white,
                    [styles.grey]:      color === ButtonColor.grey,
                    [styles.iconLeft]:  !!iconLeft,
                    [styles.iconRight]: !!iconRight,
                },
            )}
            onClick={onClick}
            disabled={disabled}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
        >
            {renderIconLeft()}
            <p>
                {text}
            </p>
            {renderIconRight()}
        </button>
    );
};

Button.propTypes = propTypes;

export default Button;
