//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes            from '@components/PropTypes';
import HeadlineSubtitleType from '@stateless/atomic/HeadlineSubtitle/HeadlineSubtitleType';

import styles from './styles.module.scss';

const propTypes = {
    headline: PropTypes.string,
    subtitle: PropTypes.string,
    type:     PropTypes.oneOfObjectValues(HeadlineSubtitleType),
};

const HeadlineSubtitle = ({
    headline = null,
    subtitle = null,
    type = HeadlineSubtitleType.siteHeader,
}) => {
    return (
        <div
            className={classNames(
                styles.headlineSubtitle,
                styles[type],
            )}
        >
            <h2 className={styles.headline}>
                {headline}
            </h2>
            <h3 className={styles.subtitle}>
                {subtitle}
            </h3>
        </div>
    );
};

HeadlineSubtitle.propTypes = propTypes;

export default HeadlineSubtitle;
