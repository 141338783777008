//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { matchPath } from 'react-router';

const routes = {
    aboutUs:         '/about-us',
    businessCard:    '/businesscard',
    dataPrivacy:     '/about-us/data-privacy',
    imprint:         '/about-us/imprint',
    login:           '/login',
    logout:          '/logout',
    screenDesign:    '/screendesign',
    shortClips:      '/shortclips',
    shortclipDetail: '/shortclips/:shortclipId',
    tutorialDetail:  '/tutorials/:tutorialId',
    tutorials:       '/tutorials',
    videoDetail:     '/videos/:videoId',
    videoclips:      '/videos',
};

export const getCurrentRouteKey = (pathname) => {
    for (const key in routes) {
        const match = matchPath(pathname, {
            path:  routes[key],
            exact: true,
        });

        if (match) {
            return key;
        }
    }

    return null;
};

export default routes;
