//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

export default (api) => {
    return {
        downloadVideoZip:  (video) => {
            return api.get(
                ApiUrls.API_VIDEOS_DOWNLOAD_ZIP(video?.iri),
                {},
                {
                    responseType: 'blob',
                },
            );
        },
        fetchVideos:       () => {
            return api.get(ApiUrls.API_VIDEOS_URL);
        },
        fetchAboutUsVideo: () => {
            return api.get(
                ApiUrls.API_VIDEOS_URL,
                {
                    aboutClipSpots: true,
                },
            );
        },
    };
};
