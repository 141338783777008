import _ from 'lodash';

import Url from '@helper/Url';

export default class VideoHelper {
    static getVideoPath(video) {
        const videoFile = video?.videoFile?.path || video?.animatedAdFile?.path;

        if (!videoFile) {
            return null;
        }

        const videoFileUrl = Url.backendImage(videoFile);

        return videoFileUrl;
    }

    static getVideoPreviewImagePath(video) {
        const previewImage = video?.videoPreviewImage || video?.animatedAdPreviewImage;

        if (!previewImage) {
            return null;
        }

        const previewImageUrl = Url.backendImage(previewImage?.path);

        return previewImageUrl;
    }

    static getVideoTextPath(video) {
        const videoText = video?.videoText?.path;

        if (!videoText) {
            return null;
        }

        const videoTextUrl = Url.backendImage(videoText);

        return videoTextUrl;
    }

    static findVideoInStack(videos, videoId) {
        return _.find(
            videos,
            {
                id: videoId,
            },
        );
    }
}
