//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default Object.freeze({
    API_CONTACT_REQUESTS_URL: 'api/contact-requests',
    API_SHORTCLIPS_URL:       'api/animated-ads',
    API_TOKENS:               'api/tokens',
    API_TUTORIALS_URL:        'api/tutorials',
    API_USER_URL:             'api/users',
    API_VIDEOS_DOWNLOAD_ZIP:  (iri) => `${iri}/download`,
    API_VIDEOS_URL:           'api/videos',
});
