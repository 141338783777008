//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@constants/ApiUrls';

const contactRequestApi = (api) => {
    return {
        submitContactRequest: ({ subject, message }) => {
            return api.post(ApiUrls.API_CONTACT_REQUESTS_URL, {
                subject,
                message,
            });
        },
    };
};

export default (api) => contactRequestApi(api);
