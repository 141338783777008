//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import PropTypes from '@components/PropTypes';
import Routes    from '@constants/Routes';
import CheckBox  from '@stateless/atomic/CheckBox';
import Link      from '@stateless/atomic/Link';

import styles from './styles.module.scss';

const propTypes = {
    checked:  PropTypes.bool,
    onChange: PropTypes.func,
};

const ApproveDataPrivacy = ({
    checked = false,
    onChange = _.noop,
}) => {
    function onClick() {
        onChange(!checked);
    }

    function renderDataPrivacyLink() {
        return (
            <Link to={Routes.dataPrivacy}>
                {I18n.t('contactForm.dataPrivacy')}
            </Link>
        );
    }

    function renderDataPrivacyText() {
        const translationKey     = 'contactForm.approveDataPrivacy';
        const translationMessage = I18n.t(translationKey);
        const parts              = translationMessage.split('{dataPrivacyLink}');

        return _.map(
            parts,
            (part, index) => (
                <React.Fragment key={index}>
                    {part}
                    {index !== parts.length - 1 && renderDataPrivacyLink()}
                </React.Fragment>
            ),
        );
    }

    return (
        <div className={styles.approveDataPrivacyContainer}>
            <CheckBox
                checked={checked}
                onClick={onClick}
            />
            <span className={styles.approveDataPrivacyText}>
                {renderDataPrivacyText()}
            </span>
        </div>
    );
};

ApproveDataPrivacy.propTypes = propTypes;

export default ApproveDataPrivacy;
