//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put } from 'redux-saga/effects';

import { ShortclipsActions } from '@slices/shortclips';
import { TutorialsActions }  from '@slices/tutorials';
import { UserActions }       from '@slices/user';
import { VideosActions }     from '@slices/videos';

function* preLoadSessionDependingData() {
    yield put(UserActions.fetchUser());
    yield put(VideosActions.fetchVideos());
    yield put(VideosActions.fetchAboutUsVideo());
    yield put(ShortclipsActions.fetchShortclips());
    yield put(TutorialsActions.fetchTutorials());
}

function* preLoadData() {
    yield put(UserActions.tryRestoreToken());
}

export default {
    preLoadData,
    preLoadSessionDependingData,
};
