//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import VideoHelper             from '@helper/VideoHelper';
import { VideoOverlayActions } from '@slices/videoOverlay';
import StatelesVideoOverlay    from '@stateless/composed/VideoOverlay';
import { selectVideo }         from '@store/selectors/videoOverlay';
import { selectOpen }          from '@store/selectors/videoOverlay';
import { selectHeadline }      from '@store/selectors/videoOverlay';

const propTypes = {};

const VideoOverlay = () => {
    const dispatch  = useDispatch();
    const open      = useSelector(selectOpen);
    const video     = useSelector(selectVideo);
    const headline  = useSelector(selectHeadline);
    const title     = headline || video?.title;
    const videoFile = VideoHelper.getVideoPath(video);

    if (!open) {
        return null;
    }

    function onCloseVideoOverlayClicked() {
        dispatch(VideoOverlayActions.close());
    }

    return (
        <StatelesVideoOverlay
            headline={title}
            onClose={onCloseVideoOverlayClicked}
            videoSrc={videoFile}
        />
    );
};

VideoOverlay.propTypes = propTypes;

export default VideoOverlay;
