//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    tutorials: [],
});

const tutorialsSlice = createSlice({
    name:     'tutorials',
    initialState,
    reducers: {
        fetchTutorials:          LoadingLevelHelper.increaseLoading((state, action) => {
        }),
        fetchTutorialsSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                tutorials: {
                    $set: action.payload.tutorials,
                },
            });
        }),
        fetchTutorialsFailed:    LoadingLevelHelper.decreaseLoading((state, action) => {
        }),
    },
});

export const TutorialsActions = tutorialsSlice.actions;

export const TutorialsReducer = tutorialsSlice.reducer;

export default tutorialsSlice;
