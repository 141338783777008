//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useSelector } from 'react-redux';

import VideoType              from '@constants/VideoType';
import ClipspotsContainerList from '@stateless/composed/ClipspotsContainerList';
import { selectVideos }       from '@store/selectors/videos';

const Home = () => {
    const videos = useSelector(selectVideos);

    return (
        <ClipspotsContainerList
            videos={videos}
            videoType={VideoType.video}
        />
    );
};
export default Home;
