//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const VideoType = Object.freeze({
    tutorial:  'tutorial',
    shortclip: 'shortclip',
    video:     'video',
});

export const findVideoStackState = (videoType) => {
    switch (videoType) {
        // @formatter:off
        case VideoType.tutorial:  return ['tutorials', 'tutorials'];
        case VideoType.shortclip: return ['shortclips', 'shortclips'];
        case VideoType.video:     return ['videos', 'videos'];
        default:                  return null;
        // @formatter:on
    }
};

export default VideoType;
