//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Routes   from '@constants/Routes';
import IconType from '@stateless/atomic/Icon/IconType';

const navigationBarEntries = [
    {
        title: I18n.t('navigationBar.videoclips'),
        icon:  IconType.videoclips,
        route: Routes.videoclips,
    },
    {
        title: I18n.t('navigationBar.shortclips'),
        icon:  IconType.shortclip,
        route: Routes.shortClips,
    },
    {
        title: I18n.t('navigationBar.businessCard'),
        icon:  IconType.businessCard,
        route: Routes.businessCard,
    },
    {
        title: I18n.t('navigationBar.aboutUs'),
        icon:  IconType.about,
        route: Routes.aboutUs,
    },
    {
        title: I18n.t('navigationBar.tutorials'),
        icon:  IconType.tutorials,
        route: Routes.tutorials,
    },
];

const logoutEntry = {
    title: I18n.t('logout'),
    icon:  IconType.logout,
    route: Routes.logout,
};

export default {
    logoutEntry,
    navigationBarEntries,
};
