//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useDispatch }  from 'react-redux';
import { useHistory }   from 'react-router';
import { generatePath } from 'react-router';

import PropTypes               from '@components/PropTypes';
import Routes                  from '@constants/Routes';
import VideoType               from '@constants/VideoType';
import VideoHelper             from '@helper/VideoHelper';
import { VideoOverlayActions } from '@slices/videoOverlay';
import ClipspotsContainer      from '@stateless/atomic/ClipspotsContainer';
import ClipspotsContainerSize  from '@stateless/atomic/ClipspotsContainer/ClipspotsContainerSize';
import IconType                from '@stateless/atomic/Icon/IconType';

const propTypes = {
    buttonProps:    PropTypes.button,
    className:      PropTypes.string,
    headline:       PropTypes.string,
    openDetailPage: PropTypes.bool,
    size:           PropTypes.clipspotsContainerSize,
    video:          PropTypes.object,
    videoType:      PropTypes.videoType,
};

const VideoClipspotsContainer = ({
    buttonProps = null,
    className = null,
    headline = null,
    openDetailPage = true,
    size = ClipspotsContainerSize.default,
    video = null,
    videoType = VideoType.video,
}) => {
    const dispatch        = useDispatch();
    const history         = useHistory();
    const videoId         = video?.id;
    const title           = headline || video?.title;
    const previewImageUrl = VideoHelper.getVideoPreviewImagePath(video);

    function openVideoOverlayClick() {
        dispatch(VideoOverlayActions.open({
            video,
            headline,
        }));
    }

    const onContainerClick = () => {
        if (
            !videoId ||
            !videoType ||
            !openDetailPage
        ) {
            return null;
        }

        return (
            () => {
                const detailRoute     = Routes[`${videoType}Detail`];
                const detailRoutePath = generatePath(detailRoute, {
                    [`${videoType}Id`]: videoId,
                });

                history.push(detailRoutePath);
            }
        );
    };

    return (
        <ClipspotsContainer
            size={size}
            className={className}
            headline={title}
            image={previewImageUrl}
            buttonProps={buttonProps}
            onImageClick={openVideoOverlayClick}
            onClick={onContainerClick()}
            icon={IconType.playButton}
        >
        </ClipspotsContainer>
    );
};

VideoClipspotsContainer.propTypes = propTypes;

export default VideoClipspotsContainer;
