//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import I18n                   from 'i18next';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

import LoadingLevelHelper from '@store/helper/LoadingLevelHelper';

const initialState = Object.freeze({
    videos: [],
});

const videosSlice = createSlice({
    name:     'videos',
    initialState,
    reducers: {
        fetchVideos:                LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loadingVideos'),
        ),
        fetchVideosSucceeded:       LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                videos: {
                    $set: action.payload.videos,
                },
            });
        }),
        fetchVideosFailed:          LoadingLevelHelper.decreaseLoading(() => {
        }),
        fetchAboutUsVideo:          LoadingLevelHelper.increaseLoading(
            () => {
            },
            I18n.t('loadingAboutUsVideo'),
        ),
        fetchAboutUsVideoSucceeded: LoadingLevelHelper.decreaseLoading((state, action) => {
            return update(state, {
                aboutUsVideo: {
                    $set: action.payload.aboutUsVideo,
                },
            });
        }),
        fetchAboutUsVideoFailed:    LoadingLevelHelper.decreaseLoading(() => {
        }),
        downloadVideoZip:           () => {
        },
    },
});

export const VideosActions = videosSlice.actions;

export const VideosReducer = videosSlice.reducer;

export const useVideos = (dispatch) => bindActionCreators(VideosActions, dispatch);

export default videosSlice;
