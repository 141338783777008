//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _               from 'lodash';
import { useLocation } from 'react-router';

import FooterHelper from '@helper/FooterHelper';
import Link         from '@stateless/atomic/Link';

import styles from './styles.module.scss';

const propTypes = {};

const Footer = () => {
    const { pathname }  = useLocation();
    const footerEntries = FooterHelper.footerEntries;
    const routeEntries  = footerEntries[pathname] || [];

    if (_.isEmpty(routeEntries)) {
        return null;
    }

    function renderEntries() {
        return _.map(
            routeEntries,
            (entry) => (
                <Link
                    className={styles.footerEntry}
                    to={entry.route}
                >
                    {entry.title}
                </Link>
            ),
        );
    }

    return (
        <div className={styles.footer}>
            {renderEntries()}
        </div>
    );
};

Footer.propTypes = propTypes;

export default Footer;
