//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import BackgroundLogo      from '@assets/images/backgroundLogo.webp';
import UpperLeftLogo       from '@assets/images/logo.png';
import LowerLeftLogo       from '@assets/images/lowerLeftLogo.webp';
import PropTypes           from '@components/PropTypes';
import AlertBoxManager     from '@connected/AlertBoxManager';
import BottomNavigationBar from '@connected/BottomNavigationBar';
import SideNavigationBar   from '@connected/SideNavigationBar';
import HeadlineSubtitle    from '@stateless/atomic/HeadlineSubtitle';
import Footer              from '@stateless/composed/Footer';
import PageTitle           from '@stateless/composed/PageTitle';

import styles from './styles.module.scss';

const propTypes = {
    backgroundLogo:      PropTypes.bool,
    bottomNavigationBar: PropTypes.bool,
    children:            PropTypes.node,
    headline:            PropTypes.string,
    lowerLeftLogo:       PropTypes.bool,
    sideNavigationBar:   PropTypes.bool,
    subtitle:            PropTypes.string,
    title:               PropTypes.string,
    upperLeftLogo:       PropTypes.bool,
};

const PageContainer = ({
    backgroundLogo = true,
    bottomNavigationBar = true,
    children = null,
    lowerLeftLogo = false,
    sideNavigationBar = true,
    title = '',
    headline = null,
    subtitle = null,
    upperLeftLogo = false,
}) => {
    function renderUpperLeftLogo() {
        if (!upperLeftLogo) {
            return null;
        }

        return (
            <img
                alt={I18n.t('pageContainer.upperLeftLogoAlt')}
                className={styles.upperLeftLogo}
                src={UpperLeftLogo}
            />
        );
    }

    function renderLowerLeftLogo() {
        if (!lowerLeftLogo) {
            return null;
        }

        return (
            <img
                alt={I18n.t('pageContainer.lowerLeftLogoAlt')}
                className={styles.lowerLeftLogo}
                src={LowerLeftLogo}
            />
        );
    }

    function renderBackgroundLogo() {
        if (!backgroundLogo) {
            return null;
        }

        return (
            <img
                alt={I18n.t('pageContainer.backgroundLogoAlt')}
                className={styles.backgroundLogo}
                src={BackgroundLogo}
            />
        );
    }

    function renderHeadlines() {
        if (
            !headline &&
            !subtitle
        ) {
            return null;
        }

        return (
            <div className={styles.headlines}>
                <HeadlineSubtitle
                    headline={headline}
                    subtitle={subtitle}
                />
            </div>
        );
    }

    function renderSideNavigationBar() {
        if (!sideNavigationBar) {
            return null;
        }

        return (
            <SideNavigationBar />
        );
    }

    function renderBottomNavigationBar() {
        if (!bottomNavigationBar) {
            return null;
        }

        return (
            <BottomNavigationBar />
        );
    }

    return (
        <div className={styles.pageContainer}>
            <PageTitle
                title={title}
            />
            {renderUpperLeftLogo()}
            {renderLowerLeftLogo()}
            {renderBackgroundLogo()}
            <div className={styles.page}>
                {renderSideNavigationBar()}
                <div className={styles.childrenContainer}>
                    {renderHeadlines()}
                    {children}
                </div>
            </div>
            <Footer />
            {renderBottomNavigationBar()}
            <AlertBoxManager />
        </div>
    );
};

PageContainer.propTypes = propTypes;

export default PageContainer;
