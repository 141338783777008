//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React, { useState } from 'react';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    save: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

const EditableText = ({
    text = null,
    save = DefaultTypes.func,
}) => {
    const [isEditing, setIsEditing]     = useState(false);
    const [currentText, setCurrentText] = useState(text);

    function onClick() {
        setIsEditing(true);
    }

    function handleSave() {
        save(currentText);
        setIsEditing(false);
    }

    function handleChange(event) {
        setCurrentText(event.target.value);
    }

    function renderText() {
        if (isEditing) {
            return (
                <textarea
                    value={currentText}
                    onChange={handleChange}
                    onBlur={handleSave}
                    autoFocus
                    className={styles.input}
                />
            );
        }

        return (
            <span className={styles.text}>
                {currentText}
            </span>
        );
    }

    return (
        <div
            className={styles.editableText}
            onClick={onClick}
        >
            {renderText()}
        </div>
    );
};

EditableText.propTypes = propTypes;

export default EditableText;
