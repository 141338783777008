//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes               from '@components/PropTypes';
import Url                     from '@helper/Url';
import VideoHelper             from '@helper/VideoHelper';
import ButtonColor             from '@stateless/atomic/Button/ButtonColor';
import IconType                from '@stateless/atomic/Icon/IconType';
import VideoClipspotsContainer from '@stateless/composed/VideoClipspotsContainer';

const propTypes = {
    buttonColor:        PropTypes.oneOfObjectValues(ButtonColor),
    downloadButtonText: PropTypes.string,
    headline:           PropTypes.string,
    video:              PropTypes.object,
    videoType:          PropTypes.videoType,
};

const DownloadableVideoClipspotsContainer = ({
    buttonColor = ButtonColor.white,
    downloadButtonText = null,
    headline = null,
    video = null,
    videoType = null,
}) => {
    const path = VideoHelper.getVideoPath(video);

    function onDownload() {
        Url.openInNewTab(path);
    }

    return (
        <VideoClipspotsContainer
            video={video}
            videoType={videoType}
            headline={headline}
            buttonProps={{
                text:      downloadButtonText,
                iconRight: IconType.download,
                color:     buttonColor,
                onClick:   onDownload,
            }}
        />
    );
};

DownloadableVideoClipspotsContainer.propTypes = propTypes;

export default DownloadableVideoClipspotsContainer;
