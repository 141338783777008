//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

export const selectToken = (state) => {
    return _.get(state, ['user', 'token']);
};

export const selectName = (state) => {
    return _.get(state, ['user', 'name']);
};

export const selectLogoAnimation = (state) => {
    return _.get(state, ['user', 'logoAnimation']);
};

export const selectBusinessCardText = (state) => {
    return _.get(state, ['user', 'businessCardText']);
};
