//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                  from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector }    from 'react-redux';
import { useLocation }    from 'react-router';

import PropTypes              from '@components/PropTypes';
import { getCurrentRouteKey } from '@constants/Routes';
import StatelessPageContainer from '@stateless/composed/PageContainer';
import PageContainerMapping   from '@stateless/composed/PageContainer/PageContainerMapping';

const propTypes = {
    children: PropTypes.node,
};

const PageContainer = ({
    children,
}) => {
    const { pathname }     = useLocation();
    const { t, i18n }      = useTranslation();
    const state            = useSelector((selectedState) => selectedState);
    const props            = PageContainerMapping[pathname] || {};
    const currentRouteName = getCurrentRouteKey(pathname) || 'pageNotFound';

    function translateIfExists(suffix) {
        const key          = `${currentRouteName}.${suffix}`;
        const suffixParams = _.reduce(_.map(
            _.get(props, `${suffix}Params`),
            (param, paramKey) => {
                const value = _.get(state, param);

                return {
                    [paramKey]: value,
                };
            },
        ));

        return (
            i18n.exists(key)
                ? t(key, suffixParams)
                : undefined
        );
    }

    const title    = translateIfExists('title');
    const headline = translateIfExists('headline');
    const subtitle = translateIfExists('subtitle');

    return (
        <StatelessPageContainer
            title={title}
            headline={headline}
            subtitle={subtitle}
            {...props}
        >
            {children}
        </StatelessPageContainer>
    );
};

PageContainer.propTypes = propTypes;

export default PageContainer;
