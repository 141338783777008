//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import DefaultTypes from '@components/DefaultTypes';
import PropTypes    from '@components/PropTypes';
import AlertBox     from '@stateless/atomic/AlertBox';

import styles from './styles.module.scss';

const propTypes = {
    alertBoxes:        PropTypes.array,
    hideButtonPressed: PropTypes.func,
};

const AlertBoxWrapper = ({
    alertBoxes = [],
    hideButtonPressed = DefaultTypes.func,
}) => {
    function renderAlertBox(data, index) {
        if (
            data &&
            index === 0
        ) {
            return (
                <AlertBox
                    onCloseButtonClick={hideButtonPressed(index)}
                    key={`alert-${index}`}
                    title={data.title}
                    text={data.text}
                    type={data.type}
                />
            );
        }

        return null;
    }

    return (
        <div
            className={classNames(
                styles.alertBoxWrapper,
                {
                    [styles.alertBoxWrapperVisible]: alertBoxes.length > 0,
                },
            )}
        >
            {alertBoxes.map(renderAlertBox)}
        </div>
    );
};

AlertBoxWrapper.propTypes = propTypes;

export default AlertBoxWrapper;
