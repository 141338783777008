//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import BackButton             from '@connected/BackButton';
import Url                    from '@helper/Url';
import Button                 from '@stateless/atomic/Button';
import ButtonColor            from '@stateless/atomic/Button/ButtonColor';
import ClipspotsContainer     from '@stateless/atomic/ClipspotsContainer';
import ClipspotsContainerSize from '@stateless/atomic/ClipspotsContainer/ClipspotsContainerSize';
import IconType               from '@stateless/atomic/Icon/IconType';
import ClipspotsContainerList from '@stateless/composed/ClipspotsContainerList';

import styles from './styles.module.scss';

const ImprintScreen = () => {
    const phone   = I18n.t('imprint.phone');
    const email   = I18n.t('imprint.email');
    const website = I18n.t('imprint.website');

    function onPhoneClick() {
        let cleanedNumber = phone.replace(/\D/g, '');

        if (phone[0] === '+') {
            cleanedNumber = `+${cleanedNumber}`;
        }

        Url.openInNewTab(`tel:${cleanedNumber}`);
    }

    function onEmailClick() {
        Url.openInNewTab(`mailto:${email}`);
    }

    function onWebsiteClick() {
        Url.openInNewTab(`https://${website}`);
    }

    function renderImprint() {
        return (
            <ClipspotsContainer headline={I18n.t('imprint.title')}>
                Geschäftsführer: Christian Engl
                <br />
                Klausenburger Straße 9/1
                <br />
                D-88069 Tettnang - Bürgermoos
                <br />
                <br />
                Ein Produkt der Engl Media GmbH
                <br />
            </ClipspotsContainer>
        );
    }

    function renderContact() {
        return (
            <ClipspotsContainer
                className={styles.contactContainer}
                headline={I18n.t('imprint.contactTitle')}
                size={ClipspotsContainerSize.wide}
            >
                <Button
                    color={ButtonColor.grey}
                    text={phone}
                    onClick={onPhoneClick}
                    iconRight={IconType.phone}
                />
                <Button
                    color={ButtonColor.grey}
                    text={email}
                    onClick={onEmailClick}
                    iconRight={IconType.mail}
                />
                <Button
                    color={ButtonColor.grey}
                    text={website}
                    onClick={onWebsiteClick}
                    iconRight={IconType.globe}
                />
            </ClipspotsContainer>
        );
    }

    return (
        <>
            <BackButton backTextKey={'backToAboutUs'} />
            <ClipspotsContainerList
                className={styles.container}
                clipspotsContainers={[
                    renderImprint(),
                    renderContact(),
                ]}
            />
        </>
    );
};

export default ImprintScreen;
