//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useHistory } from 'react-router';

import PropTypes           from '@components/PropTypes';
import StatelessBackButton from '@components/stateless/composed/BackButton';

const propTypes = {
    backTextKey: PropTypes.string,
};

const BackButton = ({
    backTextKey = 'backToOverview',
}) => {
    const history = useHistory();

    function onBackButtonClicked() {
        history.goBack();
    }

    return (
        <StatelessBackButton
            backTextKey={backTextKey}
            onBackButtonClicked={onBackButtonClicked}
        />
    );
};

BackButton.propTypes = propTypes;

export default BackButton;
